.row {
    &:hover {
        box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
        & > td.actions button {
            visibility: visible;
        }
    }

    td.actions button {
        visibility: hidden;
    }
}
