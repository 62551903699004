component[src="Neo/Menu"] {
    > div {
        width: 100%;
        background: #d3d3d3;
        border-bottom: 0.5rem solid #333;
        white-space: nowrap;
        overflow-x: auto;
        overflow-y: hidden;
        will-change: transform;

    }

    ul {
        list-style-type: none;
        max-width: 100%;

        li {
            display: inline-block;
            text-align: center;
            position: relative;
            transform: translate(0);
            &:after {
                right: 0;
                top: 0;
                bottom: 0;
                background: linear-gradient(to bottom, transparent, #eee, transparent);
                content: "";
                position: absolute;
                display: block;
                width: 1px;
            }

            &:last-child:after {
                display: none;
            }

            a {
                display: block;
                padding: 0.7rem;
                text-decoration: none !important;
                position: relative;

                i {
                    font-size: 1.4rem;
                    color: #333;
                    transition: transform 100ms;
                    margin: 0 0 0.2rem;
                    -webkit-backface-visibility: hidden;
                    backface-visibility: hidden;
                }

                &:hover {

                    i {
                        transform: scale(1.2);
                    }
                    span {
                        display: block;
                    }
                }

                &.logout i {
                    color: #a0522d;
                }

                .link-text {
                    top: 60%;
                    left: 50%;
                    position: absolute;
                    pointer-events: none;
                }

                span {
                    display: none;
                    font-size: .8rem;
                    position: relative;
                    background: rgba(0, 0, 0, 0.8);
                    color: #fff;
                    padding: 0.3rem 1rem;
                    white-space: nowrap;
                    top: 0;
                    left: -50%;
                    z-index: 1;
                    border-radius: 0.4rem;
                }
            }
        }
    }

    #quickjump {
        position: absolute;
        top: .8rem;
        right: 1rem;
        padding: .6rem;
        display: block;

        span {
            float: left;
        }

        input[type="text"] {
            width: 7rem;
            float: left;

            font-size: 13px;
            border: 1px solid #2d87e2;;
        }
    }

    #logo {
        margin: 1.2rem 3rem 1rem 3rem;
        float: left;
    }
}